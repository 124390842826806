import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PushMessage } from '../dto/PushMessage.dto';
import { ApiConstants } from '../utils/ApiConstants';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  constructor(
    private api: ApiService
  ) { }

  sendPush(dto: PushMessage) {
    return this.api.post(ApiConstants.PUSH, dto)
  }
}
