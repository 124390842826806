export class LoginRequest {
    token: string;
    fromAdmin: boolean;

    constructor(
        token: string,
        fromAdmin: boolean
    ) {
        this.token = token;
        this.fromAdmin = fromAdmin;
    }
}