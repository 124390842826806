import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { RedeemCode } from 'src/app/dto/RedeemCode.dto';
import { Reward } from 'src/app/dto/Reward.dto';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  redeem: String
  reward: String

  constructor(
    private settings: SettingsService
  ) { }

  ngOnInit() {
    this.getRedeem()
    this.getReward()
  }

  getRedeem() {
    this.settings.getRedeem().subscribe((redeem: RedeemCode) => this.redeem = redeem.code)
  }

  getReward() {
    this.settings.getReward().subscribe((reward: Reward) => this.reward = reward.text)
  }

  saveChanges() {
    this.settings.updateRedeem(this.redeem).subscribe(res => {
      this.getRedeem()
    })
    this.settings.updateReward(this.reward).subscribe(res => {
      this.getReward()
    })
  }

}
