import { Component, OnInit } from '@angular/core';
import { GalleryImage } from 'src/app/dto/GalleryImage.dto';
import { GalleryService } from 'src/app/services/gallery.service';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  pageSize = 25
  page = 1
  previousPage: any;
  galleryList: GalleryImage[]
  gallerySize = 0

  constructor(
    private gallery: GalleryService
  ) { }

  ngOnInit() {
    this.gallery.getGallery().pipe(
      map((data: any[]) => data.map((item: any) => new GalleryImage(
        item.id,
        new Date(item.date),
        item.usedForStamp
      ))),
    ).subscribe((gallery: GalleryImage[]) => {
      this.galleryList = gallery
      this.gallerySize = gallery.length
    });
  }

  removeItem(image) {
    this.gallery.deleteImage(image.id).subscribe(res => {
      let index = this.galleryList.indexOf(image)
      if (index != -1) {
        this.galleryList.splice(index, 1)
      }
    })
  }

}
