import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { ApiConstants } from '../utils/ApiConstants';
import { LoginRequest } from '../dto/LoginRequest.dto';
import { map } from 'rxjs/operators';
declare var FB: any;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  static readonly ACCESS_TOKEN_NAME = "fremtidNu_accessToken";

  private isLoggedIn = new BehaviorSubject<boolean>(this.hasToken());
  public accessToken: string;

  constructor() {}

  login(token: string) {
    let accessToken = token
    this.isLoggedIn.next(true)
    this.setAccessToken(accessToken)
  }

  logout() {
    this.isLoggedIn.next(false);
    this.accessToken = null;
    localStorage.removeItem(AuthenticationService.ACCESS_TOKEN_NAME);
  }

  isAuthenticated() {
    if(this.isLoggedIn.value) {
      return true;
    } else {
      return false;
    }
  }

  private setAccessToken(token: string) {
    this.accessToken = token;
    localStorage.setItem(AuthenticationService.ACCESS_TOKEN_NAME, this.accessToken);
  }

  private hasToken(): boolean {
    return localStorage.getItem(AuthenticationService.ACCESS_TOKEN_NAME) != null
  }
}
