import { LoginRequest } from '../dto/LoginRequest.dto';

export class ApiConstants {

    // API
    private static API = "/api";

    // VERSIONS
    private static API_V1 = `${ApiConstants.API}/v1`

    // AUTH
    public static FB_LOGIN = `${ApiConstants.API_V1}/account/facebook`

    // COLLECTIONS
    public static COLLECTIONS = `${ApiConstants.API_V1}/collections`
    public static COLLECTION(id: Number): string {
        return `${ApiConstants.API_V1}/collection/${id}`
    }

    // IMAGES
    private static IMAGES = `${ApiConstants.API_V1}/images`
    public static IMAGES_COLLECTION(id: Number): String {
        return `/images/collections/${id}_thumb.png`
    }

    // REDEEM CODE
    public static REDEEM = `${ApiConstants.API_V1}/redeemcode`

    // REWARDS
    public static REWARDS_TEXT = `${ApiConstants.API_V1}/rewards/text`

    // PUSH
    public static PUSH = `${ApiConstants.API_V1}/system/push`
}