import { Component, OnInit } from '@angular/core';
import { PushService } from 'src/app/services/push.service';
import { PushMessage } from 'src/app/dto/PushMessage.dto';

@Component({
  selector: 'app-push',
  templateUrl: './push.component.html',
  styleUrls: ['./push.component.scss']
})
export class PushComponent implements OnInit {

  model = new PushMessage("", "")
  showSuccess = false;
  showError = false;

  constructor(
    private pushService: PushService
  ) { }

  ngOnInit() {
  }

  sendPush() {
    this.pushService.sendPush(this.model).subscribe(res => {
      console.log("Success", res)
      this.showSuccess = true;
      this.showError = false;
    }, error => {
      console.log("Error", error)
      this.showSuccess = false;
      this.showError = true;
    })
  }

}
