import { BrowserModule } from '@angular/platform-browser';
import { NgModule, OnInit } from '@angular/core';
import { FormsModule }   from '@angular/forms';

import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SettingsComponent } from './components/settings/settings.component';
import { PushComponent } from './components/push/push.component';
import { HomeLayoutComponent } from './components/home-layout/home-layout.component';
import { LoginLayoutComponent } from './components/login-layout/login-layout.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { SecurePipe } from './pipes/secure.pipe';
import { LogoutComponent } from './components/logout/logout.component';

import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";

let socialConfig = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("1202256113281845")
  }
]);

export function provideSocialConfig() {
  return socialConfig
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    GalleryComponent,
    NavigationComponent,
    SettingsComponent,
    PushComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    SecurePipe,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbPaginationModule,
    SocialLoginModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: AuthServiceConfig, useFactory: provideSocialConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
}
