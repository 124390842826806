import { Injectable } from '@angular/core';

import { environment as env } from '../../environments/environment';
import { HttpHeaders, HttpParams, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

  constructor(
    private client: HttpClient,
    private authService: AuthenticationService,
    private router: Router
  ) { }

  public get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.client.get(BASE_URL + path, { params })
    .pipe(catchError((error: HttpErrorResponse) => this.formatErrors(error)));
  }

  public put(path: string, body: object = {}): Observable<any> {
    return this.client
      .put(BASE_URL + path, JSON.stringify(body), this.options)
      .pipe(catchError((error: HttpErrorResponse) => this.formatErrors(error)));
  }

  public patch(path: string, body: object = {}): Observable<any> {
    return this.client
      .patch(BASE_URL + path, JSON.stringify(body), this.options)
      .pipe(catchError((error: HttpErrorResponse) => this.formatErrors(error)));
  }

  public post(path: string, body: object = {}): Observable<any> {
    return this.client
      .post(BASE_URL + path, JSON.stringify(body), this.options)
      .pipe(catchError((error: HttpErrorResponse) => this.formatErrors(error)));
  }

  public delete(path: string): Observable<any> {
    return this.client.delete(BASE_URL + path)
    .pipe(catchError((error: HttpErrorResponse) => this.formatErrors(error)));
  }

  public formatErrors(error: HttpErrorResponse): Observable<any> {
    if (error.status == 401 || error.status == 403) {
      this.authService.logout()
      this.router.navigate(['/login'])
    }

    return throwError(error);
  }
}

const BASE_URL = env.baseUrl;