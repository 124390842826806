import { Component, OnInit } from '@angular/core';
import { LoginRequest } from 'src/app/dto/LoginRequest.dto';
import { Router } from '@angular/router';
//import { AuthenticationService } from 'src/app/services/authentication.service';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ApiConstants } from 'src/app/utils/ApiConstants';
import { map, catchError } from 'rxjs/operators';
import { rootRenderNodes } from '@angular/core/src/view';
import { AuthService, FacebookLoginProvider } from 'angularx-social-login';
import { AuthenticationService } from 'src/app/services/authentication.service';
declare var FB: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  showError: boolean = false;
  error: string = null

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private authentication: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.authService.authState.subscribe((user) => {
      if (user != null) {
        this.internalLogin(user.authToken)
      } else {
        this.authentication.logout()
      }
    });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  internalLogin(token: string) {
    this.showError = false;
    this.error = null;

    var root = this
    var dto = new LoginRequest(token, true)
    
    this.api.post(ApiConstants.FB_LOGIN, dto).subscribe(res => {
      if (res.isAdmin) {
        root.authentication.login(res.token)
        root.router.navigate(['/gallery'])
      } else {
        this.setError()
      }
    }, error => {
      this.setError()
    });
  }

  setError() {
    this.showError = true;
    this.error = "You are not authorized.";
  }
}
