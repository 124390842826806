import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { ApiConstants } from '../utils/ApiConstants';
import { RedeemCode } from '../dto/RedeemCode.dto';
import { Reward } from '../dto/Reward.dto';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private api: ApiService
  ) { }

  getRedeem() {
    return this.api.get(ApiConstants.REDEEM);
  }

  getReward() {
    return this.api.get(ApiConstants.REWARDS_TEXT);
  }

  updateRedeem(redeemText: String) {
    return this.api.put(ApiConstants.REDEEM, new RedeemCode(redeemText.toString()))
  }

  updateReward(rewardText: String) {
    return this.api.put(ApiConstants.REWARDS_TEXT, new Reward(rewardText.toString()))
  }
}
