import { ApiConstants } from '../utils/ApiConstants';
import { environment as env } from '../../environments/environment';

export class GalleryImage {
    id: number;
    url: String;
    date: Date;
    usedForStamp: boolean;

    constructor(
        id: number,
        date: Date,
        usedForStamp: boolean
    ) {
        this.id = id;
        this.url = env.baseUrl + ApiConstants.IMAGES_COLLECTION(id);
        this.date = date;
        this.usedForStamp = usedForStamp;
    }

}