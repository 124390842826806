import { Injectable } from '@angular/core';
import { GalleryImage } from '../dto/GalleryImage.dto';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { ApiConstants } from '../utils/ApiConstants';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(
    private api: ApiService
  ) { }

  private _imageList = new BehaviorSubject<GalleryImage[]>([])

  getGallery(): Observable<GalleryImage[]> {
    let params = new HttpParams().set('fromAdmin', 'true');
    return this.api.get(ApiConstants.COLLECTIONS, params)
  }

  deleteImage(id: number) {
    return this.api.delete(ApiConstants.COLLECTION(id))
  }
}
