import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
declare var FB: any;

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private auth: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.auth.logout()
    FB.logout();
    this.router.navigate(['/logout']);

    /*var root = this
    this.auth.initFb()
    FB.logout(function(response) {
      if (!response.session) {
        root.auth.logout()
        root.router.navigate(['/login'])
      }
    })*/
  }

}
